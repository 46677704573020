<template>
  <b-card title="Listar Zonas">
    <listarZonas />
  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listarZonas from '../../Listagens/Zonas/ListarZonas.vue'

export default {
  components: {
    BCard, listarZonas,
  },

}
</script>
